import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PropTypes } from 'prop-types';

const AccordionPanel = ({ panelTitle, panelContent }) => {
  return (
    <div>
      <Accordion className='accordion-panel'>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls='accordion-panel-header'
          id='panel-header'>
          <div className='accordion-panel-title'>{panelTitle}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='accordion-panel-content'>{panelContent}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionPanel;

AccordionPanel.propTypes = {
  panelTitle: PropTypes.string,
  panelContent: PropTypes.any
};
