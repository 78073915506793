/**
 * Module to hold API calls
 */
const { REACT_APP_API_URL } = process.env;
import { poll } from '../modules/Poll';

/**
 * Retrieves the latest operational model results
 * @returns model recommendations
 */
export async function optimize(token) {
  const response = await fetch(REACT_APP_API_URL + `/api/1.0/operational`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const data = await response.json();
  if (response.status === 200) {
    return data;
  } else {
    throw new Error(`${response.status} ${data.message}`);
  }
}

/**
 * Runs the operational model
 * @returns model recommendations
 */
export async function runOperationalModel(token) {
  const response = await fetch(REACT_APP_API_URL + `/api/1.0/operational`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    method: 'POST'
  });
  const data = await response.json();
  if (response.status === 200) {
    return data;
  } else {
    throw new Error(`${response.status} ${data.message}`);
  }
}

/**
 * Loads the lastest strategic model results
 * @returns model strategic model results
 */
export async function loadStrategicResults(token) {
  const response = await fetch(REACT_APP_API_URL + `/api/1.0/strategic`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const data = await response.json();

  if (response.status === 200) {
    return data;
  } else {
    throw new Error(`${response.status} ${data.message}`);
  }
}

/**
 * Runs the strategic model
 * @returns model reccommendations
 */
export async function runStrategicModel(token) {
  const currentTime = Date.now();

  const response = await fetch(REACT_APP_API_URL + `/api/1.0/strategic`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    method: 'POST'
  });
  const data = await response.json();
  if (response.status === 200) {
    return data;
  } else if (response.status == 503) {
    const result = await pollLoadStrategicModel(currentTime, token);

    if (!result) throw new Error('Timeout waiting for the Strategic Model to complete');

    return JSON.stringify({ success: true });
  } else {
    throw new Error(`${response.status} ${data.message}`);
  }
}

async function pollLoadStrategicModel(currentTime, token) {
  // AWS HTTP Gateway enforces a non-configurable 30 second timeout. The Strategic Model
  // can take up to two minutes to run. Check back every 10 seconds until the model is complete.
  return await poll(
    async () => {
      const strategicModel = await loadStrategicResults(token);
      // If the model is more recent, return true
      return Date.parse(strategicModel.inputTime) > currentTime;
    },
    10000,
    90
  );
}

/**
 * Retrieves vendor data including name and locations details
 * @returns vendor data
 */
export async function getVendorData(token) {
  const response = await fetch(REACT_APP_API_URL + `/api/1.0/vendors`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const data = await response.json();
  if (response.status === 200) {
    return data;
  } else {
    throw new Error(`${response.status} ${data.message}`);
  }
}

/**
 * uploads file based on data type, data stored in dynamo, returns presigned url to upload file to s3
 * @returns presigned url
 */
export async function uploadFile(token, formData, dataType, filename) {
  try {
    const response = await fetch(
      REACT_APP_API_URL +
        `/api/1.0/upload?datatype=${encodeURIComponent(dataType)}&filename=${encodeURIComponent(
          filename
        )}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*'
        },
        method: 'POST',
        body: formData
      }
    );
    const data = await response.json();
    if (response.ok) {
      const presignedUrl = data.url;
      return presignedUrl;
    } else {
      throw new Error(`${data.message} ${response.status}`);
    }
  } catch (error) {
    console.error('Error uploading file to server:', error);
    throw error;
  }
}

/**
 * Puts file in s3 using presigned url returned from upload file function
 * @returns success or throws error
 */
export async function uploadFileToPresignedUrl(token, presignedUrl, file) {
  try {
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: file
    });
  } catch (error) {
    console.error('Error uploading file to presigned URL:', error);
    throw new Error(`Failed to upload file to presigned URL: ${response.status}`);
  }
}

/**
 * Retrieve data for data management
 * @returns latest data from dynamo for requested data type
 */
export async function handleRetrieveFile(dataType, token) {
  const response = await fetch(
    REACT_APP_API_URL + `/api/1.0/retrieve?dataType=${encodeURIComponent(dataType)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  const data = await response.json();
  if (response.status === 200) {
    return data;
  } else if (response.status === 404) {
    return response.status;
  } else {
    throw new Error(`${data.message} ${response.status}`);
  }
}

/**
 * Download data file to desktop
 * @returns data management file download
 */
export async function handleDownloadDataFile(dataType, token) {
  const response = await fetch(
    REACT_APP_API_URL + `/api/1.0/download_data?dataType=${encodeURIComponent(dataType)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  const data = await response.json();
  if (response.status === 200) {
    return data.url;
  } else if (response.status === 404) {
    return response.status;
  } else {
    throw new Error(`${data.message} ${response.status}`);
  }
}
