import React from 'react';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const ExportToExcelButton = ({ data, fileName, buttonLabel, transformData, className }) => {
  const downloadExcel = () => {
    const transformedData = transformData ? transformData(data) : data;

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <Button className={className} onClick={() => downloadExcel(data)}>
      {buttonLabel}
    </Button>
  );
};

export default ExportToExcelButton;

ExportToExcelButton.propTypes = {
  data: PropTypes.array.isRequired, //data as array of objects
  fileName: PropTypes.string.isRequired, //name for the downloaded file
  buttonLabel: PropTypes.string.isRequired, //button label
  transformData: PropTypes.func, //optional function passed for custom transformation of data -
  //e.g. rename key for header, transform list of objects to array for consumption
  className: PropTypes.string //optional className for styling
};
