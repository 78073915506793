/**
 * @component SimpleRose Reusable Side Navigation component
 * 
 * @desc This component takes navigation items and renders
 * a collapsible side navigation.
 * 
 *  @see [Figma Design](https://www.figma.com/design/MRWUYiqr277GIpRfpq8gGF/REX---MVP-2?node-id=1-15810&t=CnRtPSuPHbM07QVQ-4)
 * 
 * @param {Array} navItems - An array of navigation items.
 * Each item should have the following shape:
 * {
 *   icon: PropTypes.element.isRequired,
 *   path: PropTypes.string.isRequired,
 *   text: PropTypes.string.isRequired
 * }
 * @param {Function} onExpanded - A callback function that is called
 * when the side navigation is expanded or collapsed.
 * 
 * @example
 * import React from 'react';
 * import { SideNavigation } from './components/SideNavigation';
 * 
 * const navItems = [
 *   {
 *     icon: <HomeIcon />,
 *     path: '/home',
 *     text: 'Home'
 *   },
 *   {
 *     icon: <SettingsIcon />,
 *     path: '/settings',
 *     text: 'Settings'
 *   }
 * ];
 * 
 * const App = () => {
 *   const handleExpanded = (expanded) => {
 *     console.log(`Side navigation is ${expanded ? 'expanded' : 'collapsed'}`);
 *   };
 * 
 *   return (
 *     <div>
 *       <SideNavigation navItems={navItems} onExpanded={handleExpanded} />
 *       {/* Other components 
 */

import './SideNavigation.scss';
import React, { useState, useEffect } from 'react';
import {
  ClickAwayListener,
  Drawer,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { NavLink, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';

export const SideNavigation = ({ navItems, onExpanded }) => {
  const [expanded, setExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const location = useLocation();

  useEffect(() => {
    // Find the index of the nav item corresponding to the current pathname
    const foundIndex = navItems.findIndex((navItem) => location.pathname.startsWith(navItem.path));
    if (foundIndex !== -1) {
      setActiveIndex(foundIndex);
      setFocusedIndex(foundIndex);
    }
  }, [location.pathname, navItems]);

  const handleClickAway = () => {
    setExpanded(false);
    if (onExpanded) {
      onExpanded(false);
    }
  };

  const handleClick = (index) => {
    if (index >= 0 && index < navItems.length) {
      setActiveIndex(index);
      setFocusedIndex(index);
    }
    setExpanded(true);
    if (onExpanded) {
      onExpanded(true);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' || event.key === 'Tab') {
      event.preventDefault();
      const nextIndex = (focusedIndex + 1) % navItems.length;
      navItems[focusedIndex].ref.blur(); // Remove hover from current button
      navItems[nextIndex].ref.focus(); // Add hover to next button
      setFocusedIndex(nextIndex);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const prevIndex = (focusedIndex - 1 + navItems.length) % navItems.length;
      navItems[focusedIndex].ref.blur(); // Remove hover from current button
      navItems[prevIndex].ref.focus(); // Add hover to previous button
      setFocusedIndex(prevIndex);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      navItems[focusedIndex].ref.click(); // Click the current button
    }
  };

  const shouldLinkBeActive = (navItem, index) => {
    return location.pathname.startsWith(navItem.path) || activeIndex === index;
  };

  const getListButtonStyling = (navItem, index) => {
    if (expanded) {
      if (shouldLinkBeActive(navItem, index))
        return 'side-navigation-active-button side-navigation-expanded-button';
      return 'side-navigation-expanded-button';
    }
    return 'side-navigation-button';
  };

  return (
    <Drawer
      role='side-navigation-drawer'
      className={expanded ? 'side-navigation-expanded' : 'side-navigation'}
      classes={{
        paper: 'side-navigation-paper'
      }}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      variant='permanent'>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={'side-navigation-list-container'} 
          onClick={handleClick}
        >
          <List>
            {navItems.map((navItem, index) => (
              <ListItemButton
                role='menu-item-button'
                className={getListButtonStyling(navItem, index)}
                component={NavLink}
                key={index}
                onClick={() => handleClick(index)}
                ref={el => navItem.ref = el}
                to={navItem.path}>
                <ListItemIcon 
                  className={
                    shouldLinkBeActive(navItem, index)
                      ? 'side-navigation-active-icon'
                      : 'side-navigation-icon'
                  }>
                    <Tooltip title={navItem.text} placement='top' arrow>
                      {navItem.icon}
                    </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className={
                    shouldLinkBeActive(navItem, index)
                      ? 'side-navigation-active-text'
                      : 'side-navigation-text'
                  }
                  primary={expanded ? navItem.text : ''}
                />
              </ListItemButton>
            ))}
          </List>
        </div>
      </ClickAwayListener>
    </Drawer>
  );
};

SideNavigation.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  onExpanded: PropTypes.func
};
