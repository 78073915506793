import { React } from 'react';

import DataGrid from './DataGrid';

const OpenPOTable = ({ openPOs }) => {
  const openPOTableColumnStyles = [
    {},
    {},
    {},
    {},
    {
      maxWidth: '100px',
    },
    {
      textAlign: 'right',
      paddingLeft: '0px',
      paddingRight: '60px',
      formatValue: (value) => {
        // Convert string to number if possible
        const numericValue = !isNaN(parseFloat(value))
          ? parseFloat(value)
          : value;
        // Format numeric value with commas
        return typeof numericValue === 'number'
          ? numericValue.toLocaleString()
          : value;
      }
    },
    {
      textAlign: 'right',
      paddingRight: '30px',
    },
    {
      textAlign: 'right',
      paddingRight: '30px',
    }
  ];

  return (
    <div className='open-po-table-container'>
      <h3>Open POs</h3>
      <DataGrid
        jsonData={JSON.stringify(openPOs)}
        initialOrderBy={'Plant'}
        columnNames={[
          'Plant',
          'Vendor',
          'Name',
          'City',
          'State',
          'Qty. to Receive',
          'P.O. Number',
          'Date Expected',          
        ]}
        columnStyles={openPOTableColumnStyles}
      />
    </div>
  );
};

export default OpenPOTable;
