/**
 * Maps incoming array of arrays for strategic model warnings to key value pairs
 * @param {[["","" ,""], ["", "", ""]]} data array of arrays
 * @param {['','']}keys the keys to be mapped to
 * @returns returns an array of objects,
 * where each object represents a set of key-value pairs derived from the input data.
 * Each object corresponds to a row of data, with keys taken from the keys array and
 * values from the corresponding positions in the inner arrays of the input data.
 */

export const arrayOfArraysToKeyValuePairs = (data, keys) => {
  // Check if data is an array and has at least one inner array
  if (!Array.isArray(data) || data.length < 2) {
    return []; // Return an empty array if data is not in the expected format
  }

  const [...arrays] = data;

  // Map each inner array to key-value pairs
  const keyValuePairs = arrays.map((array) => {
    // Create an object with keys from the 'keys' array and values from the inner array
    return array.reduce((acc, value, i) => {
      value = value;

      acc[keys[i]] = value;
      return acc;
    }, {});
  });

  return keyValuePairs;
};
