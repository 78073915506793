import React, { useState, useEffect } from 'react';
import SortableIcon from '../components/SortableIcon';
import { 
  Box, 
  Paper,
 } from '@mui/material';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import './DataGrid.scss';

const DataGrid = ({ jsonData, initialOrderBy, columnNames, columnStyles, valueFormatter }) => {
  const [orderBy, setOrderBy] = useState(initialOrderBy || '');
  const [data, setData] = useState([]);

  // Parse JSON data and update state
  useEffect(() => {
    try {
      const parsedData = JSON.parse(jsonData);

      if(!Array.isArray(parsedData))
        throw new Error('Data must be an array')

      setData(parsedData);
    } catch (error) {
      console.error('Error parsing JSON data:', error);
    }

    // Set initial order by if provided
    if (initialOrderBy) {
      setOrderBy(initialOrderBy);
    }
  }, [jsonData, initialOrderBy]);

  // make sure data is present before rendering
  if (!data || data.length === 0) return null;
  
  const sortComparator = (a, b, order) => {
    const aValue = String(a) || '';
    const bValue = String(b) || '';

    // Handle empty values
    if (aValue === '' && bValue === '') return 0;
    if (aValue === '') return order === 'asc' ? -1 : 1;
    if (bValue === '') return order === 'asc' ? 1 : -1;

    // Perform regular comparison
    if (order === 'asc') {
      return bValue.localeCompare(aValue);
    }
    return aValue.localeCompare(bValue);
  };

  const columns = Object.keys(data[0]).map((column, index) => ({
    align: (columnStyles && columnStyles[index]?.textAlign) || 'left',
    cellClassName: 'data-grid-cell',
    editable: false,
    field: column,
    flex: (columnStyles && columnStyles[index]?.width) ? 0 : 1,
    headerAlign: 'left',
    headerClassName: 'data-grid-header',
    headerName: columnNames && columnNames[index] || column,
    minWidth: parseInt((columnStyles && columnStyles[index]?.width) || Math.max(110, column.length * 5.3)),
    maxWidth: parseInt((columnStyles && columnStyles[index]?.maxWidth) || 200),
    renderCell: (params) => (
      <div
          style={{
            paddingLeft: (columnStyles && columnStyles[index]?.paddingLeft) || "10px",
            paddingRight: (columnStyles && columnStyles[index]?.paddingRight) || "20px",
            textAlign: (columnStyles && columnStyles[index]?.textAlign) || 'left',
          }}
        >
          {params.value}
        </div>
    ),
    sortComparator: sortComparator,
    valueGetter: (params) => {
      if (columnStyles && columnStyles[index] && columnStyles[index].formatValue) {
        return columnStyles[index].formatValue(params.value);
      }
      if (valueFormatter) {
        return valueFormatter(column, params.value);
      }
      return params.value;
    },
  }));
  
  const rows = data.map((row, rowIndex) => ({
    id: rowIndex,
    ...row
  }));

  return (
    <Box component={Paper} className='data-grid-container'>
      {data ? (
        <MuiDataGrid className='data-grid' disableRowSelectionOnClick
          columns={columns}
          rows={rows}
          getRowClassName={(params) =>
            `data-grid-row data-grid-row--${params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}`
          }
          getRowHeight={() => 'auto'}
          initialState={{
            sorting: {
              sortModel: [{ field: orderBy, sort: 'asc' }],
            },
          }}
          slots={{
            columnSortedAscendingIcon: () => <SortableIcon sorted={true} ascending={true} />,
            columnSortedDescendingIcon: () => <SortableIcon sorted={true} ascending={false} />,
            columnUnsortedIcon: () => <SortableIcon sorted={false} ascending={true} />,
          }}
        />
      ) : null}
    </Box>
  );
};

export default DataGrid;
