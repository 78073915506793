import { 
  useState, 
} from 'react';
import { 
  Alert,
  AlertTitle,
  Snackbar as MuiSnackbar,
} from '@mui/material';

const Snackbar = () => {
  const [snackbarConfig, setSnackbarConfig] = useState(null);

  Snackbar.show = (config) => {
    setSnackbarConfig(config);
  };


  Snackbar.showError = (err) => {
    setSnackbarConfig({
      severity: 'error',
      title: 'Error',
      message: err,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarConfig(null);
  };

  if (snackbarConfig) {
    return (
        <MuiSnackbar
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'middle',
          }}
          autoHideDuration={6000}
          open={true}
          onClose={handleCloseSnackbar}
          sx={{ 
            top: '0%',
          }}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity={snackbarConfig.severity || 'info'}
            variant='filled'
          >
            {snackbarConfig.title && <AlertTitle>
              {snackbarConfig.title}
            </AlertTitle>}
            {snackbarConfig.message || ''}
          </Alert>
        </MuiSnackbar>
    );
  }
  
  return null;

};

export default Snackbar;
