import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VendorList } from '../components/VendorList';
import '../App.scss';
import { Button } from '@mui/material';
import * as Api from '../modules/Api';
import { useAuth0 } from '@auth0/auth0-react';
import loadingIndicator from '../assets/SimpleRose-progress-indicator-4x.gif';
import './OperationalModel.scss';
import { formatDate } from '../modules/FormatDate';
import Snackbar from '../components/Snackbar';

export const OperationalModel = () => {
  const [operationalResults, setOperationalResults] = useState(null);
  const [runningModel, setRunningModel] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);

  useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  // GET call to /operational
  const loadOperationalResults = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await Api.optimize(token);
      setOperationalResults(() => response);
    } catch (err) {
      Snackbar.showError(`${err}`);
      return;
    } finally {
      setLoadingModel(false);
    }
  };

  // POST call to /operational
  const runOperationalModel = async () => {
    var response;

    try {
      setRunningModel(true);
      const token = await getAccessTokenSilently();
      response = await Api.runOperationalModel(token);
    } catch (err) {
      Snackbar.showError(`${err}`);
      return;
    } finally {
      setRunningModel(false);
    }

    await loadOperationalResults();
    setLoadingModel(false);
  };

  useEffect(() => {
    setLoadingModel(true);
    loadOperationalResults();
  }, []);

  return (
    <div className='operational-model-page-container'>
      {operationalResults && !runningModel && !loadingModel ? (
        <>
          <div className='operational-model-header-bar'>
            <div className='recommendation-engine-header'>Operational Model</div>
            <div className='right-align-header-items'>
              <p className='last-updated'>Last Updated: {formatDate(operationalResults.inputTime)}</p>
              <Button
                role='run-operational-model-button'
                className='run-operational-model-button'
                variant='contained'
                onClick={() => {
                  setRunningModel(true);
                  runOperationalModel();
                }}>
                Refresh Recommendations
              </Button>
            </div>
          </div>

          {operationalResults && <VendorList operationalResults={operationalResults} />}
        </>
      ) : (
        <div className='loading-container'>
          {loadingModel ? (
            <div className='loading-message' role='loading-message'>
              Loading the Operational Model...
            </div>
          ) : (
            <div className='loading-message' role='loading-message'>
              Running the Operational Model...
            </div>
          )}
          <img
            className='loading-indicator'
            role='loading-indicator'
            src={loadingIndicator}
            alt='loading-indicator'
          />
        </div>
      )}
    </div>
  );
};
