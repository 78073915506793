/**
 * Calls a given function multiple times, waiting a given
 * number of seconds between each call. The method will 
 * return when the given function returns true or the
 * number of calls exceeds a given number of maximum iterations.
 * @param {*} func Function to be invoked, return true to stop invokations
 * @param {*} ms The number of milliseconds to wait between function calls
 * @param {*} maxIterations The maximum number of times to call the given function
 * 
 * @returns True if any calls to func return true, false otherwise
 */
export async function poll(func, timeout, maxIterations){
    var iterations = 0;
  
    while(iterations++ < maxIterations) {
      await wait(timeout)    
              
      const ret = await func();

      if(ret  == true)
        return true;    
    } 
    return false;
  }
  
  /**
   * Pause execution for the given number of milliseconds
   * @param {} ms The number of milliseconds to pause execution
   * @returns A promise, await to pause
   */
  function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }