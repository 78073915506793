import React, { useState } from 'react';
import './DataSelectionMenu.scss';
import Divider from '@mui/material/Divider';
import { Button } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const DataSelectionMenu = ({ menuItems, onItemClick }) => {
  const [selectedItem, setSelectedItem] = useState(menuItems[0]);

  const handleItemClick = (menuItem) => {
    // Prevent double click
    if (selectedItem === menuItem) {
      return;
    }
    setSelectedItem(menuItem);
    onItemClick(menuItem); // Call the callback function provided by the parent component
  };

  return (
    <div className='data-selection-container'>
      {menuItems.map((menuItem, index) => (
        <div key={index}>
          <Button
            className={`data-selection-button ${selectedItem === menuItem ? 'selected' : ''}`}
            onClick={() => handleItemClick(menuItem)}
            variant='contained'
            endIcon={<KeyboardArrowRightIcon />}>
            {menuItem}
          </Button>
          {index < menuItems.length - 1 && <Divider flexItem />}
        </div>
      ))}
    </div>
  );
};

export default DataSelectionMenu;
