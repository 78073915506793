import * as React from 'react';
import { 
  BarPlot,
  ChartContainer,
  ChartsLegend,
  ChartsTooltip, 
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
} from '@mui/x-charts';
import './PlantInventoryLevelChart.scss';

const customize = {
  width: 420,
  height: 330,
  margin: { top: 10, bottom: 125, left: 80, right: 0 },
};

export default function PlantInventoryLevelChart({ chartData, chartKeys, chartUnits }) {
  return (
    <div role='plant-inventory-level-chart' className='plant-inventory-level-chart'>
      <ChartContainer
          xAxis={[{
              dataKey: 'month',
              scaleType: 'band',
              id: 'x-axis-id',
          }]}
          yAxis={
            [
              {
                min: 0,
              }
            ]
          }
          series={chartKeys.map((key) => ({
            type: key.type,
            dataKey: key.name,
            label: key.label || key.name,
            stack: key.stack,
            color: key.color,
          }))}
          dataset={chartData}
          {...customize}          
        >
          <ChartsTooltip />
          <ChartsLegend
            hidden={false}
            direction='row'
            position={{ vertical: 'bottom', horizontal: 'middle' }}
          />
          <ChartsXAxis 
            position='bottom' 
            axisId='x-axis-id'
          />
          <ChartsYAxis
            position='left'
            label={chartUnits || ''}
          />
          <BarPlot />
          <LinePlot />
        </ChartContainer>
      </div>
  );
}
