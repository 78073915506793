import { React, useEffect, useState } from 'react';
import Snackbar from '../components/Snackbar';
import { useAuth0 } from '@auth0/auth0-react';
import * as Api from '../modules/Api';
import { arrayOfArraysToKeyValuePairs } from '../modules/MapKeyValuePairs';
import DataGrid from '../components/DataGrid';
import MetricList from '../components/MetricList';
import PlantInventoryLevelChart from '../components/PlantInventoryLevelChart';
import TabPanel from '../components/TabPanel';
import { StyledEngineProvider, Button, Tabs, Tab, Box } from '@mui/material';
import '../Alerts.scss';
import './StrategicModel.scss';
import loadingIndicator from '../assets/SimpleRose-progress-indicator-4x.gif';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import ExportToExcelButton from '../components/ExportToExcelButton';
import AccordionPanel from '../components/AccordionPanel';
import { formatDate } from '../modules/FormatDate';

//TODO: Break out helper functions
const StrategicModel = () => {
  const [vendorData, setVendorData] = useState(null);
  const [vendorMappingData, setVendorMappingData] = useState(null);
  const [runningModel, setRunningModel] = useState(false);
  const [assumptions, setAssumptions] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [vendorTableData, setVendorTableData] = useState(null);
  const [inventoryLevelData, setInventoryLevelData] = useState(null);
  const [globalMetrics, setGlobalMetrics] = useState(null);
  const [resfreshingModel, setRefreshingModel] = useState(false);
  const [numberOfAssumptions, setNumberOfAssumptions] = useState(12);
  const [numberOfErrors, setNumberOfErrors] = useState(null);
  const [numberOfWarnings, setNumberOfWarnings] = useState(null);
  const [numberOfInfoMessages, setNumberOfInfoMessages] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { getAccessTokenSilently } = useAuth0();

  const initialize = async () => {
    loadStrategicResults();
    getVendorData();
  };

  useEffect(() => {
    initialize();
  }, []);

  var vendor_data = null;
  var vendor_mappings = null;

  const addVendorDataToVendorMappings = function () {
    if (!vendor_mappings || !vendor_data) {
      return;
    }

    var vendorMappings = [];

    vendor_mappings.vendorMappings.map((vendorMapping) => {
      var vendor = vendor_data[vendorMapping.vendor];

      if (vendor) {
        vendorMappings.push({
          ...vendorMapping,
          ...vendor
        });
      } else vendorMappings.push(vendorMapping);
    });

    vendor_mappings.vendorMappings = vendorMappings;
    setVendorMappingData(vendor_mappings);
    setVendorTableData(mapVendorsForDataTable(vendorMappings));
  };

  // calls addVendorDataToVendorMappings
  function mapVendorData(response, setVendorMappingData) {
    var vendorMappings = [];

    response.result.map((tuple) => {
      vendorMappings.push({
        vendor: tuple[0],
        plant: tuple[1],
        total_supply_in_lbs: tuple[2],
        product: tuple[3]
      });
    });
    var vendorMappingData = {
      inputTime: new Date(response.inputTime).toLocaleString('en-US'),
      vendorMappings: vendorMappings
    };

    vendor_mappings = vendorMappingData;

    addVendorDataToVendorMappings();
  }

  // call to /vendors
  const getVendorData = async function () {
    var response;

    try {
      const token = await getAccessTokenSilently();
      response = await Api.getVendorData(token);
    } catch (err) {
      Snackbar.showError(`${err}`);
      return;
    }

    setVendorData(response);

    vendor_data = response;
    addVendorDataToVendorMappings(response);
  };

  // GET call to /strategic
  const loadStrategicResults = async function () {
    var response;
    try {
      const token = await getAccessTokenSilently();
      response = await Api.loadStrategicResults(token);
    } catch (err) {
      Snackbar.showError(`${err}`);
      return;
    }

    mapVendorData(response, setVendorMappingData);
    mapAssumptionsResponse(response);
    mapInventoryLevels(response);
    mapGlobalMetrics(response.output_metrics);
    return;
  };

  // POST call to /strategic
  const runStrategicModel = async function () {
    var response;

    try {
      setRunningModel(true);
      const token = await getAccessTokenSilently();
      response = await Api.runStrategicModel(token);
    } catch (err) {
      Snackbar.showError(`${err}`);
      return;
    } finally {
      setRunningModel(false);
    }

    await loadStrategicResults();
    setRefreshingModel(false);
  };

  // map Vendor key value pairs for data table
  const mapVendorsForDataTable = (dataArray) => {
    const keys = ['Vendor', 'Name', 'City', 'State', 'Total_Supply_in_lbs', 'Product', 'Plant'];
    return dataArray.map((obj) => {
      const newObj = {};
      keys.forEach((key) => {
        const lowerCaseKey = key.toLowerCase(); // Convert key to lowercase
        const objKey = Object.keys(obj).find((k) => k.toLowerCase() === lowerCaseKey); // Find matching key in the object regardless of case
        newObj[key] = objKey !== undefined ? obj[objKey] : ''; // Assign value or empty string if key is not found
      });

      return newObj;
    });
  };

  // map assumptions key value pairs for data table
  const mapAssumptionsResponse = (response) => {
    //the first array in response.warnings contains a message about the success of the run, extract it
    const warningArrays = response.warnings;
    setNumberOfAssumptions(warningArrays.length);

    const keys = [
      'Key',
      'Vendor Name',
      'Vendor Location',
      'Found In',
      'Not Found In',
      'Message',
      'Severity Level'
    ];
    const mappedAssumptions = arrayOfArraysToKeyValuePairs(warningArrays, keys);

    setNumberOfErrors(mappedAssumptions.filter((item) => item['Severity Level'] == 'Error').length);
    setNumberOfWarnings(
      mappedAssumptions.filter((item) => item['Severity Level'] == 'Warning').length
    );
    setNumberOfInfoMessages(
      mappedAssumptions.filter((item) => item['Severity Level'] == 'Informational').length
    );

    setAssumptions(mappedAssumptions);
  };

  const strategicTableColumnStyles = [
    {},
    {
      width: '250px',
    },
    {},
    {},
    {
      textAlign: 'right',
      paddingLeft: '0px',
      paddingRight: '60px',
      width: '155px',
      formatValue: (value) => {
        // Convert string to number if possible
        const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
        // Format numeric value with commas
        return typeof numericValue === 'number' ? numericValue.toLocaleString() : value;
      }
    }
  ];

  const mapInventoryLevels = (response) => {
    const keyToChartMapping = {
      'Demand (barrels)': 0,
      'Production (barrels)': 0,
      'Malt Safety Stock (pounds)': 1,
      'Total Inventory Level (pounds)': 1,
      'Malt Inventory Level (pounds)': 1,
      'Plant Capacity (pallets)': 2,
      'Inventory Level (pallets)': 2
    };

    const inventoryLevelData = {};
    const graphData = response.graph;
    const outputMetrics = response.output_metrics;

    graphData.map((item) => {
      console.log('item=', item);
      const plant = item[0];

      var plantData = inventoryLevelData[plant];

      if (!plantData) {
        plantData = {
          metrics: mapPlantMetrics(outputMetrics[plant]),
          charts: [
            {
              keys: [
                {
                  name: 'Demand (barrels)',
                  label: 'Demand',
                  type: 'line',
                  color: 'red',
                },
                {
                  name: 'Production (barrels)',
                  label: 'Production',
                  type: 'line',
                  color: 'green',
                },
              ],
              units: 'barrels',
              chartData: []
            },
            {
              keys: [
                {
                  name: 'Malt Inventory Level (pounds)',
                  label: 'Malt Inventory',
                  type: 'bar',
                  stack: 'Inventory Level (pounds)',
                  color: 'blue',
                },
                {
                  name: 'Corn Inventory Level (pounds)',
                  label: 'Corn Inventory',
                  type: 'bar',
                  stack: 'Inventory Level (pounds)',
                  color: 'orange',
                },
                {
                  name: 'Malt Safety Stock (pounds)',
                  label: 'Malt Safety Stock',
                  type: 'line',
                  color: 'black',
                },
              ],
              units: 'pounds',
              chartData: []
            },
            {
              keys: [
                {
                  name: 'Plant Capacity (pallets)',
                  label: 'Plant Capacity',
                  type: 'line',
                  color: 'brown',
                },
                {
                  name: 'Inventory Level (pallets)',
                  label: ' Inventory Level',
                  type: 'line',
                  color: 'purple',
                },
              ],
              units: 'pallets',
              chartData: []
            }
          ]
        };

        inventoryLevelData[plant] = plantData;
      }

      const dimension = item[1];
      const chartNumber = keyToChartMapping[dimension];
      const chart = plantData.charts[chartNumber];
      const chartData = chart.chartData;

      const firstMonthDataIndex = 2;
      for (var i = 0; i < item.length - firstMonthDataIndex; i++) {
        if (!chartData[i]) {
          const monthNumber = i + 1;
          chartData.push({
            month: outputMetrics['All Plants']['Month Labels'][i]
          });
        }

        chartData[i][dimension] = item[i + firstMonthDataIndex];
      }

      for (var i = 0; i < chartData.length; i++) {
        const total = chartData[i]['Total Inventory Level (pounds)'] || 0;
        const malt = chartData[i]['Malt Inventory Level (pounds)'] || 0;
        chartData[i]['Corn Inventory Level (pounds)'] = total - malt;
      }

    });

    setInventoryLevelData(inventoryLevelData);
  };

  const mapPlantMetrics = (plantMetrics) => {
    return {
      total: mapPlantOutputMetrics(plantMetrics['Total Supply (pounds)']),
      useable: mapPlantOutputMetrics(plantMetrics['Usable Supply (pounds)']),
      production: {
        'Production (barrels)': plantMetrics['Production (barrels)'],
      },
      demand: {
        'Demand (barrels)': plantMetrics['Demand (barrels)'],
      },
      percentage: {
        'Production (barrels)': plantMetrics['Production (percent of demand)'],
      },
      cost: {
        'Estimated Freight Cost': plantMetrics['Estimated Freight Cost'],
      },
    };
  };


  const mapGlobalMetrics = (outputMetrics) => {
    setGlobalMetrics({
      demand: {
        'Demand (barrels)': outputMetrics['All Plants']['Demand (barrels)'],
      },
      production: {
        'Production (barrels)': outputMetrics['All Plants']['Production (barrels)'],
      },
      percentage: {
        'Production (barrels)': outputMetrics['All Plants']['Production (percent of demand)'],
      },
      cost: {
        'Estimated Freight Cost': outputMetrics['All Plants']['Estimated Freight Cost'],
      },
      total: mapPlantOutputMetrics(outputMetrics['All Plants']['Total Supply (pounds)']),
      useable: mapPlantOutputMetrics(outputMetrics['All Plants']['Usable Supply (pounds)'])
    });
  };

  const mapPlantOutputMetrics = (outputMetrics) => {
    const {
      Corn: outputMetricsPlantCornValue,
      Malt: outputMetricsPlantMaltValue,
      'Percent Corn': outputMetricsPlantDemandValue,
      Total: outputMetricsPlantTotalSupplyValue,
      ...outputMetricsPlantOther
    } = outputMetrics;

    const outputMetricsPlantMapped = {};
    if (outputMetricsPlantTotalSupplyValue) {
      outputMetricsPlantMapped['Combined Valid Supply (pounds)'] = outputMetricsPlantTotalSupplyValue;
    }
    if (outputMetricsPlantCornValue) {
      outputMetricsPlantMapped['Valid Corn Supply (pounds)'] = outputMetricsPlantCornValue;
    }
    if (outputMetricsPlantMaltValue) {
      outputMetricsPlantMapped['Valid Malt Supply (pounds)'] = outputMetricsPlantMaltValue;
    }

    return {
      ...outputMetricsPlantMapped,
      ...outputMetricsPlantOther
    };
  };

  const metricFormatOptions = {
    'Estimated Freight Cost': (value) => {
      return value?.toLocaleString?.('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    },
    'Percent Corn': (value) => {
      return value.toLocaleString?.('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    },
    'Production (barrels)': (value, isSecondary) => isSecondary 
      ? value.toLocaleString?.('en-US', {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : !isNaN(parseFloat(value)) ? parseFloat(value)?.toLocaleString() : value,
  };

  const transformStrategicDataForExport = (data) => {
    return data.map((obj) => {
      // Create a new object with modified key
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        if (key === 'Total_Supply_in_lbs') {
          newObj['Total Supply (lbs)'] = obj[key];
        } else {
          newObj[key] = obj[key];
        }
      });
      return newObj;
    });
  };

  return (
    <StyledEngineProvider>
      <div className='strategic-model-page'>
        {!runningModel && vendorMappingData ? (
          <>
            <div className='strategic-model-header-bar'>
              <div className='strategic-model-page-title'>Strategic Model</div>
              <div className='right-align-header-items'>
                <p className='last-updated'>Last Updated: {formatDate(vendorMappingData.inputTime)}</p>
                <Button
                  role='refresh-strategic-model-button'
                  className='refresh-strategic-model-button'
                  variant='contained'
                  onClick={() => {
                    setRefreshingModel(true);
                    runStrategicModel();
                    getVendorData();
                  }}>
                  Run Model
                </Button>
              </div>
            </div>
            <div className='global-metrics'>
              <MetricList
                secondaryMetrics={globalMetrics.total}
                metrics={globalMetrics.useable}
                metricLabel='usable'
                secondaryMetricLabel='available'
                formatOptions={metricFormatOptions}
              />
              <MetricList
                metrics={globalMetrics.production}
                secondaryMetrics={globalMetrics.percentage}
                secondaryMetricLabel='of demand'
                formatOptions={metricFormatOptions}
              />
              <MetricList 
                metrics={globalMetrics.demand} 
                formatOptions={metricFormatOptions} 
              />
              <MetricList 
                metrics={globalMetrics.cost} 
                formatOptions={metricFormatOptions} 
              />
            </div>
          </>
        ) : null}

        {!runningModel && vendorMappingData ? (
          <Box className='strategic-model-content-container'>
            <Box className='tab-menu' sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                onChange={handleTabChange}
                value={tabValue}
                aria-label='strategic model page content'
                className='tab-menu'>
                <Tab
                  id='vendor-to-plant-tab'
                  aria-controls='vendor-to-plant-panel'
                  className='nav-tab'
                  label='Vendor to Plant Recommendations'
                  value={0}
                />
                <Tab
                  id='inventory-levels-tab'
                  aria-controls='inventory-levels-panel'
                  className='nav-tab'
                  label='Plant Details'
                  value={1}
                />
                <Tab
                  id='model-assumptions-tab'
                  aria-controls='model-assumptions-panel'
                  className='nav-tab'
                  label={
                    <div className='model-assumptions-tab-label tab-label'>
                      Data Validations
                      {numberOfErrors ? (
                        <div className='model-assumptions-tab-label'>
                          <ErrorIcon className='model-assumptions-tab-label-icon' color='error' />
                          <span className='model-assumptions-tab-label-message'>
                            {numberOfErrors}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {numberOfWarnings ? (
                        <div className='model-assumptions-tab-label'>
                          <WarningIcon
                            className='model-assumptions-tab-label-icon'
                            color='warning'
                          />
                          <span className='model-assumptions-tab-label-message'>
                            {numberOfWarnings}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {numberOfInfoMessages ? (
                        <div className='model-assumptions-tab-label'>
                          <InfoIcon className='model-assumptions-tab-label-icon' color='info' />{' '}
                          <span className='model-assumptions-tab-label-message'>
                            {numberOfInfoMessages}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                  value={2}
                />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <div
                id='vendor-to-plant-panel'
                role='tabPanel'
                aria-labelledby='vendor-to-plant-tab'
                className='vendor-to-plant-panel'>
                <ExportToExcelButton
                  className={'strategic-model-export-button'}
                  data={vendorTableData}
                  transformData={transformStrategicDataForExport}
                  fileName={'Strategic Model Results.xlsx'}
                  buttonLabel={'Export Strategic Model Results'}
                />
                <DataGrid
                  jsonData={JSON.stringify(vendorTableData)}
                  initialOrderBy={'Name'}
                  columnNames={[
                    'Vendor',
                    'Name',
                    'City',
                    'State',
                    'Total Supply (lbs)',
                    'Product',
                    'Plant'
                  ]}
                  columnStyles={strategicTableColumnStyles}
                />
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <div
                id='inventory-level-panel'
                role='tabPanel'
                aria-labelledby='inventory-level-tab'
                className='inventory-level-panel'>
                {Object.keys(inventoryLevelData).map((plant, index) => (
                  <AccordionPanel
                    key={index}
                    panelTitle={plant}
                    panelContent={
                      <>
                        <div className='metrics-container'>
                          <MetricList
                            secondaryMetrics={inventoryLevelData[plant].metrics.total}
                            metrics={inventoryLevelData[plant].metrics.useable}
                            metricLabel='useable'
                            secondaryMetricLabel='available'
                            formatOptions={metricFormatOptions}
                          />
                          <MetricList
                            metrics={inventoryLevelData[plant].metrics.production}
                            secondaryMetrics={inventoryLevelData[plant].metrics.percentage}
                            secondaryMetricLabel={'of demand'}
                            formatOptions={metricFormatOptions}
                          />
                          <MetricList
                            metrics={inventoryLevelData[plant].metrics.demand}
                            formatOptions={metricFormatOptions}
                          />
                          <MetricList
                            metrics={inventoryLevelData[plant].metrics.cost}
                            formatOptions={metricFormatOptions}
                          />
                        </div>
                        <div className='chart-container'>
                          {/* exclude the first graph, it will be rendered in the supply and demand panel */}
                          {inventoryLevelData[plant].charts.map((chart, index) => (
                            <PlantInventoryLevelChart
                              key={index}
                              chartData={chart.chartData}
                              chartKeys={chart.keys}
                              chartUnits={chart.units}
                            />
                          ))}
                        </div>
                      </>
                    }></AccordionPanel>
                ))}
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <div
                id='model-assumptions-panel'
                role='tabPanel'
                aria-labelledby='model-assumptions-tab'
                className='model-assumptions-panel'>
                <ExportToExcelButton
                  className={'data-validations-export-button'}
                  data={assumptions}
                  transformData={transformStrategicDataForExport}
                  fileName={'Data Validations.xlsx'}
                  buttonLabel={'Export Data Validations'}
                />
                <DataGrid jsonData={JSON.stringify(assumptions)} />
              </div>
            </TabPanel>
          </Box>
        ) : (
          <>
            <div className='loading-container'>
              {resfreshingModel ? (
                <div className='loading-message' role='loading-message'>
                  Running the Strategic Model...
                </div>
              ) : (
                <div className='loading-message' role='loading-message'>
                  Retrieving the Strategic Model...
                </div>
              )}
              <img
                className='loading-indicator'
                role='loading-indicator'
                src={loadingIndicator}
                alt='loading-indicator'
              />
            </div>
          </>
        )}
      </div>
    </StyledEngineProvider>
  );
};

export default StrategicModel;
