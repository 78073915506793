import "./VendorSelect.scss";

import Select from "react-select";

const VendorSelect = ({ options, onChange }) => {
  return (
    <div className="vendor-select-wrapper">
      <div className="text-field">
        <div className="text-field-sub">
          <div className="state-layer">
            <div className="content">
              <div className="placeholder-text">
                <div className="placeholder-sub-text">
                  <div className="vendor-select-container">
                    <Select
                      options={options}
                      onChange={onChange}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          text: "black",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="label-text">
                <div className="label-sub-text">Please choose a vendor *</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorSelect;
