import './App.scss';
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { OperationalModel } from './pages/OperationalModel';
import { Header } from './components/Header';
import { SideNavigation } from './components/SideNavigation';
import DataManagement from './pages/DataManagement';
import StrategicModel from './pages/StrategicModel';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from './components/LoginButton';
import Snackbar from './components/Snackbar';
import LightbulbTwoTone from '@mui/icons-material/LightbulbTwoTone';
import SportsBarTwoToneIcon from '@mui/icons-material/SportsBarTwoTone';
import TableViewTwoToneIcon from '@mui/icons-material/TableViewTwoTone';

const App = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, error } = useAuth0();
  const [authChecked, setAuthChecked] = useState(false);
  const [sideNavExpanded, setSideNavExpanded] = useState(false);

  // https://auth0.com/blog/complete-guide-to-react-user-authentication/ - TODO: introduce Auth0Provider with history?
  // If the user is not authenticated, immediately send them to the Auth0 login page
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    } else if (!isLoading) {
      setAuthChecked(true);
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (error) {
    Snackbar.show({
      severity: 'error',
      title: `${error.name}`,
      message: `${error.message}`,
    });
  }

  const navItems = [
    {
      path: '/operational',
      text: 'Live recommendations',
      icon: <SportsBarTwoToneIcon />,
      element: <OperationalModel />
    },
    {
      path: '/strategic',
      text: 'Strategic planning',
      icon: <LightbulbTwoTone />,
      element: <StrategicModel />
    },
    {
      path: '/data',
      text: 'Data management',
      icon: <TableViewTwoToneIcon />,
      element: <DataManagement />
    }
  ];

  return (
    <>
      <Header />
      <Snackbar />
      <div className='App'>
        {authChecked && (
          <>
            {!isAuthenticated ? (
              <div className='login-message'>
                <span>Please Login: </span>
                <LoginButton />
              </div>
            ) : (
              <>
                <SideNavigation navItems={navItems} onExpanded={setSideNavExpanded} />
                <div className={`app-content ${sideNavExpanded ? 'faded' : ''}`}>
                  <Routes>
                    <Route path='/' element={<Navigate to='/operational' />} />
                    {navItems.map((navItem, idx) => (
                      <Route key={idx} path={navItem.path} exact element={navItem.element} />
                    ))}
                  </Routes>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default App;
