import React from 'react';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import './SortableIcon.scss';

const SortableIcon = ({ ascending, sorted }) => {
  // Define class names based on props
  const upClassName = ascending ? (sorted ? 'pink' : 'black') : 'gray';
  const downClassName = ascending ? 'gray' : sorted ? 'pink' : 'black';

  return (
    <div className='sortable-icon-container'>
      <ArrowDropUpTwoToneIcon className={`sortable-icon up ${upClassName}`} />
      <ArrowDropDownTwoToneIcon
        className={`sortable-icon down ${downClassName}`}
      />
    </div>
  );
};

export default SortableIcon;
