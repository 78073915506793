import './Header.scss';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import simpleRoseLogo from '../assets/SimpleRose-full-logo.png';

import { useAuth0 } from '@auth0/auth0-react';

import React from 'react';

export const Header = () => {
  const { user, isAuthenticated, logout } = useAuth0();

  const logoutWithRedirect = (event) => {
    handleClose(event);

    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <div className='header'>
        <img className='header-logo' src={simpleRoseLogo} alt='logo' />

        <div className='right-aligned-header-items'>
          <div className='header-user-nav'>
            {isAuthenticated && (
              <Stack direction='row' spacing={2}>
                <div>
                  <Button
                    ref={anchorRef}
                    id='composition-button'
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup='true'
                    onClick={handleToggle}
                    className='header-user-profile-button'>
                    <img
                      src={user.picture}
                      alt='Profile'
                      className='header-user-profile-picture'
                      width='50'
                    />
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement='bottom-start'
                    transition
                    disablePortal
                    className='header-user-dropdown'>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                        }}>
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id='composition-menu'
                              aria-labelledby='composition-button'
                              onKeyDown={handleListKeyDown}>
                              <MenuItem className='header-user-name'>
                                <h4>{user.name}</h4>
                              </MenuItem>
                              <MenuItem onClick={logoutWithRedirect}>Logout</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Stack>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
