import React, { useState, useRef } from 'react';
import * as Api from '../modules/Api';
import { useAuth0 } from '@auth0/auth0-react';
import Snackbar from './Snackbar';
import './FileUploader.scss';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Button } from '@mui/material';
import loadingIndicator from '../assets/SimpleRose-Progress-transparent.gif';
import { validateExcelFile } from '../modules/DataFileValidation';
import { RequiredColumns } from '../modules/RequiredColumnsForDataValidation';

const FileUploader = ({ onUploadSuccess, selectedDataType }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  //reference to the file input field
  const fileInputField = useRef(null);

  const isExcelFile = (fileName) => {
    // Convert the file name to lowercase to ensure case-insensitive comparison
    const lowerCaseFileName = fileName.toLowerCase();

    // Check if the file name ends with one of the Excel file extensions
    return lowerCaseFileName.endsWith('.xlsx') || lowerCaseFileName.endsWith('.xlsm');
  };

  //allows click anywhere in the outlined drop box div to use the ref to the file input field
  const handleFileExplorerClick = (event) => {
    //prevents the click event from being triggered twice if the input field inside the dropdown div is clicked directly
    if (event.target !== fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (!file) {
      return;
    }
    handleFileChange(file);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    handleFileChange(file);
  };

  const handleFileChange = (file) => {
    setFileName(file.name);
    if (!isExcelFile(file.name)) {
      Snackbar.show({ 
        message: `Please select an Excel file to proceed`
      });
      return;
    }

    validateExcelFile(file, RequiredColumns, selectedDataType)
      .then(() => {
        // Validation passed, set the selected file
        setSelectedFile(file);
      })
      .catch((error) => {
        // Validation failed, show error message
        Snackbar.showError(`There was a problem validating the file: ${error}`);
      });
  };

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    const filename = selectedFile.name;
    try {
      const token = await getAccessTokenSilently();
      const presignedUrl = await Api.uploadFile(token, formData, selectedDataType, filename);
      await Api.uploadFileToPresignedUrl(token, presignedUrl, selectedFile);
      setUploadedFile(selectedFile);
      setSelectedFile(null);
      fileInputField.current.value = '';
      Snackbar.show({
        severity: 'success',
        title: 'Success',
        message: 'file uploaded',
      });
      onUploadSuccess();
    } catch (err) {
      Snackbar.showError(`There was a problem uploading the file: ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      Snackbar.show({
        message: `Please select a file to proceed`,
      });
      return;
    }
    setIsLoading(true);
    uploadFile();
  };

  const clearSelection = () => {
    setSelectedFile(null);
    setUploadedFile(null);
    //reset the input field
    if (fileInputField.current) {
      fileInputField.current.value = '';
    }
  };

  return (
    <>
      <div className='file-upload-container'>
        <div
          className={`drop-zone ${isDragging ? 'drag-over' : ''}`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleFileExplorerClick}>
          <FileUploadOutlinedIcon className='file-upload-icon' />
          <input
            className='file-input'
            type='file'
            ref={fileInputField}
            onChange={handleFileInputChange}
          />
          {!selectedFile ? (
            <div className='upload-instructions'>
              {isDragging ? 'Drop File Here' : 'Click to Select or Drag and Drop File'}
            </div>
          ) : (
            <div>{selectedFile.name}</div>
          )}
          <div className='file-upload-helper-text'>accepted file formats: .xlsm .xlsx</div>
        </div>
        {isLoading && (
          <>
            <div className='overlay'></div>
            <div className='loading-container'>
              <img
                className='loading-indicator'
                role='loading-spinner'
                src={loadingIndicator}
                alt='loading-spinner'
              />
              <div className='uploading-message'>Uploading File...</div>
            </div>
          </>
        )}
        {selectedFile && !isLoading && (
          <div>
            <Button className='submit-button' onClick={handleUpload}>
              Submit
            </Button>
          </div>
        )}
        {uploadedFile && (
          <div>
            <p>File Uploaded: {uploadedFile.name}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploader;
