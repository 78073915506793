import './MetricList.scss';

const MetricList = ({
  metrics,
  formatOptions,
  secondaryMetrics,
  metricLabel,
  secondaryMetricLabel,
}) => {
  const formatValue = (metricKey, isSecondary = false) => {
    const value = isSecondary ? secondaryMetrics[metricKey] : metrics[metricKey];

    if (formatOptions && formatOptions.hasOwnProperty(metricKey))
      return formatOptions[metricKey](value, isSecondary);

    // Convert string to number if possible
    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    // Format numeric value with commas
    return typeof numericValue === 'number' ? numericValue.toLocaleString() : value;
  };

  return (
    <div className='metric-list' role='metric-list'>
      {Object.keys(metrics).map((metricKey, index) => (
        <div className='metric' key={index}>
          <div className='metric-label'>{metricKey}</div>

          <div className='metric-object'>
            <div className='metric-value'>{formatValue(metricKey)}</div>
            <div className='metric-sub-label'>{metricLabel}</div>
          </div>
          {secondaryMetrics && secondaryMetrics[metricKey] && (
            <div className='metric-object'>
              <div className='secondary-metric'>{formatValue(metricKey, true)}</div>
              <div className='secondary-metric-sub-label'>{secondaryMetricLabel}</div>
            </div>
          )}

        </div>
      ))}
    </div>
  );
};

export default MetricList;
