import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      role='login-button'
      className='login-button'
      onClick={() => loginWithRedirect()}
    >
      Log In
    </button>
  );
};
