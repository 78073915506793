import { React, useState, useEffect } from 'react';
import './DataManagement.scss';
import DataSelectionMenu from '../components/DataSelectionMenu';
import FileUploader from '../components/FileUploader';
import DataGrid from '../components/DataGrid';
import * as Api from '../modules/Api';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Skeleton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { formatDate } from '../modules/FormatDate';
import Snackbar from '../components/Snackbar';
import loadingIndicator from '../assets/SimpleRose-Progress-transparent.gif';

const DataManagement = () => {
  const [fileContent, setFileContent] = useState(null);
  const [selectedDataType, setSelectedDataType] = useState('Vendor Products');
  const [uploadRequested, setUploadRequested] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');
  const [dataNotFound, setDataNotFound] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const handleDataRetrieval = async (selectedDataType) => {
    const token = await getAccessTokenSilently();

    try {
      const response = await Api.handleRetrieveFile(selectedDataType, token);
      if (response === 404) {
        setDataNotFound(true);
        return;
      } else if (response) {
        setFileContent(response.content.Data);
        const date = formatDate(response.content.InputTime);
        setLastUpdated(date);
        setDataNotFound(false);
      }
    } catch (error) {
      console.error('Error:', error);
      Snackbar.showError(`${error}`);
    }
  };

  const handleUploadButtonClick = () => {
    setUploadRequested(true);
  };

  const handleDownload = async (selectedDataType) => {
    if (isFetching) return;
    const token = await getAccessTokenSilently();
    try {
      setIsFetching(true);
      const response = await Api.handleDownloadDataFile(selectedDataType, token);
      if (response === 404) {
        Snackbar.show({
          message: 'File requested for download was not found.'
        });
        return;
      } else if (response) {
        const url = new URL(response);

        // Create a temporary anchor element
        const link = document.createElement('a');

        // Set the href attribute to the presigned URL
        link.href = url;

        // Programmatically trigger the click event on the anchor element
        link.click();

        Snackbar.show({
          severity: 'success', 
          message: 'File downloaded successfully!'
        });
        
      } else {
        throw new Error('Failed to download file');
      }
    } catch (error) {
      console.error('Error:', error);
      Snackbar.showError(`${error}`);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    // Load the initial content for the first item in the menu
    const loadInitialContent = async () => {
      await handleDataRetrieval(selectedDataType);
    };

    loadInitialContent(selectedDataType);
  }, [selectedDataType]);

  const showSkeleton = !dataNotFound && !uploadRequested && !fileContent;

  const dataMenuItems = [
    'Vendor Products',
    'Freight Rates',
    'Plant Parameters',
    'Strategic Production Plan',
    'Strategic Supply',
    'Vendor Locations'
  ];

  const handleDataMenuItemClick = (menuItem) => {
    // Update the selected data type and load corresponding data
    setSelectedDataType(menuItem);
    // Add your data loading logic here...
    setFileContent(null);
    setSelectedDataType(menuItem);
    handleDataRetrieval(menuItem);
    setUploadRequested(false);
    setDataNotFound(false);
  };

  return (
    <div className='data-management-container'>
      <div className='column-1'>
        <DataSelectionMenu menuItems={dataMenuItems} onItemClick={handleDataMenuItemClick} />
      </div>
      <div className='column-2'>
        <div className='file-content-container'>
          {showSkeleton ? (
            <Skeleton
              variant='rectangular'
              className={'file-content-container-skeleton'}
              animation='wave'
            />
          ) : (
            <>
              {fileContent && (
                <>
                  <div className='content-header'>
                    <h4>{selectedDataType}
                    {selectedDataType == dataMenuItems[3] ? ' (barrels)' : ''}
                    {selectedDataType == dataMenuItems[4] ? ' (pounds)' : ''}</h4>
                    <div className='last-updated'>Last updated: {lastUpdated}</div>
                  </div>
                  <DataGrid jsonData={fileContent} valueFormatter={(col, val) => 
                    (["Zip", "Start", "End"].includes(col) || isNaN(parseFloat(val))) ? val : parseFloat(val)?.toLocaleString()}/>
                </>
              )}
              {dataNotFound && !uploadRequested && (
                <>
                  <div className='data-title-message'>Data Not Found for {selectedDataType}</div>
                </>
              )}
              {dataNotFound && uploadRequested && (
                <>
                  <div className='data-title-message'>Uploading Data for {selectedDataType}</div>
                </>
              )}
              <div className='button-container'>
                {isFetching ? (
                  <>
                    <div className='overlay'></div>
                    <div className='loading-container'>
                      <img
                        className='loading-indicator'
                        role='loading-spinner'
                        src={loadingIndicator}
                        alt='loading-spinner'
                      />
                      <div className='uploading-message'>Downloading File...</div>
                    </div>
                  </>
                ) : (
                  <>
                    {!uploadRequested && (
                      <>
                        <Button
                          variant='contained'
                          className='upload-button'
                          title='Upload'
                          endIcon={<FileUploadIcon />}
                          onClick={() => handleUploadButtonClick()}>
                          Upload New
                        </Button>
                        {!dataNotFound && (
                          <Button
                            variant='contained'
                            className={'download-button'}
                            title={'Download'}
                            endIcon={<FileDownloadIcon />}
                            onClick={() => handleDownload(selectedDataType)}
                            disabled={isFetching}>
                            Download File
                          </Button>
                        )}
                        {fileUrl && (
                          <a href={fileUrl} download>
                            Download File
                          </a>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>

              {uploadRequested && (
                <div className={fileContent ? 'file-uploader' : 'file-uploader-no-content'}>
                  <FileUploader
                    selectedDataType={selectedDataType}
                    onUploadSuccess={() => {
                      setFileContent((prevContent) => (prevContent, null));
                      setUploadRequested(false);
                      handleDataRetrieval(selectedDataType);
                    }}
                    onClose={() => setUploadRequested(false)}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataManagement;
