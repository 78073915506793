import * as XLSX from 'xlsx';

export const validateExcelFile = (file, RequiredColumns, dataType) => {
  return new Promise((resolve, reject) => {
    // Step 1: Create FileReader instance
    const reader = new FileReader();

    // Step 2: Setup event handlers
    reader.onload = (event) => {
      handleFileLoad(event, RequiredColumns, dataType, resolve, reject);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    // Step 3: Read file as ArrayBuffer
    reader.readAsArrayBuffer(file);
  });
};

// Function to handle file loading
const handleFileLoad = (event, RequiredColumns, dataType, resolve, reject) => {
  const requiredColumns = RequiredColumns[dataType];
  if (!requiredColumns) {
    reject('Invalid data type');
    return;
  }

  const data = new Uint8Array(event.target.result);

  const workbook = XLSX.read(data, { type: 'array' });

  // Assuming the first sheet is the one we want to validate
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];

  // Convert sheet to JSON
  const json = XLSX.utils.sheet_to_json(worksheet, { defval: '' });

  // Get a single row
  const row = json[0];

  // Extract keys
  const keys = Object.keys(row);

  // Check for missing columns
  const missingColumns = requiredColumns.filter((column) => !keys.includes(column));

  if (missingColumns.length === 0) {
    resolve(true); // All required columns are present
  } else {
    reject(`Missing required columns: ${missingColumns.join(', ')}`);
  }
};
