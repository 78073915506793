import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import './Card.scss';

const Card = ({ tier }) => {

  return (
    <div className='card-container'>
      <MuiCard
        className={
          tier.restricted 
            ? 'restricted'
            : tier.recommended
            ? 'recommended'
            : null
        }
        >
        <Typography variant='h6' className='card-price'>
          <Typography className='dollar-sign'>$</Typography>{tier.price}
        </Typography>
        <CardHeader
          title={
            <div className='card-title-container'>
                <Typography variant='h6' className='card-title'>
                  {tier.title}
                </Typography>
                {tier.subheader && <Typography variant='subtitle2' className='card-subheader'>
                  {tier.subheader}
                </Typography>}
            </div>
          }
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
          }}
        />
        <CardContent>
          {tier.description.map((line, index) => {
            const [label, value] = line.split(':');
            return (
              <div key={`line-${index}`} className={`card-description-line-container 
                              ${index < 3 ? 'card-description-line-top-row' : 'card-description-line-other-row'}`}>
                <Typography variant='subtitle2' key={`label-${index}`} className='card-description-label'>
                  {label}
                </Typography>
                <Typography variant='caption' key={`value-${index}`} className='card-description-value'>
                  {value || '\u00A0'}
                </Typography>
              </div>
            )
          })}
        </CardContent>
      </MuiCard>
    </div>
  );
};

export default Card;

Card.propTypes = {
  tier: PropTypes.object.isRequired
};
