import { React, useEffect, useState, useRef } from 'react';
import loadingIndicator from '../assets/SimpleRose-progress-indicator-4x.gif';
import '../Alerts.scss';
import Card from './Card';
import VendorSelect from './VendorSelect';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import OpenPOTable from './OpenPOTable';
import './VendorList.scss';

export const VendorList = ({ operationalResults }) => {
  const [vendors, setVendors] = useState(null);
  const [vendorQualities, setVendorQualities] = useState(null);
  const [merchandise, setMerchandise] = useState(null);
  const [recommendationsByVendor, setRecommendationsByVendor] = useState(null);
  const [vendorSelectList, setVendorSelectList] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [openPOs, setOpenPOs] = useState(null);

  const [plantData, setPlantData] = useState(null);
  const [isCriteriaVisible, setIsCriteriaVisible] = useState(false);

  const criteriaContainerRef = useRef(null);

  const onVendorSelect = (selectedOption) => {
    var merchandise = false;

    var vendor = vendors[selectedOption.value];

    var cards = [];

    var recommendations = recommendationsByVendor[selectedOption.value];
    recommendations
      .filter((recommendation) => {
        if (recommendation.plant == 'Merchandise') {
          merchandise = true;
          return false;
        }

        return true;
      })
      .map((recommendation) => {
        var plant = plantData[recommendation.plant];

        const plantUtil = plant.plant_utilization.toLocaleString?.('en-US', {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });

        var percent_usable = '';
        var product = '';
        var restricted = '';
        var at_capacity = '';
        var net_weight_per_pallet = '';
        var stackable_factor = '';

        var plantProduct = vendorQualities[recommendation.plant];

        if (plantProduct) {
          var productMetrics = plantProduct[selectedOption.value];

          if (productMetrics) {
            if (productMetrics['Percent Usable']) {
              percent_usable = productMetrics['Percent Usable'].toLocaleString?.('en-US', {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              });
            }

            product = productMetrics.Product;
            net_weight_per_pallet = productMetrics['Net Weight Per Pallet'];
            stackable_factor = productMetrics['Stackable Factor'];
                        
          } else {
            console.error(
              'No vendor product metrics for plant ' +
                recommendation.plant +
                ' and vendor ' +
                selectedOption.value
            );
          }
        } else {
          console.error('No plant product metrics for plant: ' + recommendation.plant);
        }

        restricted = recommendation.restricted;
        at_capacity = recommendation.at_capacity;

        cards.push({
          title: recommendation.plant,
          subheader: 'Rank: ' + recommendation.option_number,
          price: recommendation.freight_cost.toLocaleString(),
          option_number: recommendation.option_number,
          restricted: (restricted || at_capacity),
          description: [
            'Corn On Hand: ' +
              (recommendation.current_inventory * (recommendation.corn_ratio / 100)).toLocaleString(undefined, {
                maximumFractionDigits: 0
              }) + ' pounds',
            'Malt On Hand: ' +
              (recommendation.current_inventory - (recommendation.current_inventory * (recommendation.corn_ratio / 100))).toLocaleString(undefined, {
                maximumFractionDigits: 0
              }) + ' pounds',
            'Days of Supply: ' + plant.malt_days_of_supply.toFixed(1),
            'Utilization: ' + plantUtil,
            'Plant Availability: ' + (restricted ? 'Restricted' : at_capacity ? 'At Capacity' : 'Available'),
            'Plant Prioritized: ' + plant.prioritized,
            'Open POs: ' + recommendation.open_po_count,
            'Next Receipt Date: ' + new Date(recommendation.next_shipment_date).toLocaleDateString('en-us'),            
            'Vendor Product: ' + (product ? product : '--'),
            'Vendor Usability: ' + (percent_usable ? percent_usable : '--'),        
            
          ]
        });
      });

    var preferredCard = cards.find((card) => {
      return card.option_number == 1;
    });

    if (preferredCard) {
      preferredCard.recommended = true;
    }

    // Alphabetize the cards by plant name
    cards.sort((a, b) => {
      var textA = a.title.toUpperCase();
      var textB = b.title.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setMerchandise(merchandise);

    setSelectedVendor({
      vendor: selectedOption.value,
      ...vendor,
      recommendations: cards
    });
  };

  const strategicRecommendationForVendor = (selectedVendor, recommendations) => {
    for (const vendor in recommendations) {
      if (vendor === selectedVendor) {
        return recommendations[vendor];
      }
    }
    return 'No Recommendation Available';
  };

  const loadRecommendations = async function () {
    const response = operationalResults;

    var plantData = {};
    response.plant_output.map((plant) => {
      plantData[plant.plant] = plant;
    });
    setPlantData(plantData);

    // Organize recommendations by vendor
    var recommendationsByVendor = {};
    var vendorSelectList = [];
    response.recommendations_output.map((item) => {
      if (!recommendationsByVendor.hasOwnProperty(item.vendor)) {
        recommendationsByVendor[item.vendor] = [];

        var vendor = response.vendors[item.vendor];

        if (vendor) {
          vendorSelectList.push({
            value: item.vendor,
            label:
              item.vendor + ' - ' + vendor['Name'] + ' - ' + vendor['City'] + ', ' + vendor['State']
          });
        } else console.error('No vendor data found for vendor ID: ' + item.vendor);
      }

      recommendationsByVendor[item.vendor].push(item);
    });

    vendorSelectList.sort();
    setVendorSelectList(vendorSelectList);
    setRecommendationsByVendor(recommendationsByVendor);
    setVendors(response.vendors);
    setVendorQualities(response.vendor_qualities);
    setOpenPOs(response.open_pos);
  };

  useEffect(() => {
    loadRecommendations();
  }, []);

  useEffect(() => {
    if (isCriteriaVisible && criteriaContainerRef.current) {
      criteriaContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [isCriteriaVisible])

  return (
    <div className='vendor-list-container'>
      {vendorSelectList == null ? (
        <div className='vendor-loading-container'>
          <span className='greeting-message' role='greeting'>
            Loading Recommendations...
          </span>
          <img
            className='loading-indicator'
            role='loading-indicator'
            src={loadingIndicator}
            alt='loading-indicator'
          />
        </div>
      ) : null}
      <div className='vendor-container'>
        {vendorSelectList != null ? (
          <div className='vendor-information-container'>
            <VendorSelect options={vendorSelectList} onChange={onVendorSelect} />
            {selectedVendor != null && <div className='vendor-name'>
              {selectedVendor.vendor} - {selectedVendor.Name}
            </div>}
          </div>
        ) : null}
        {selectedVendor != null && 
          <div className='strategic-recommendation-chip'>
            Latest Strategic Recommendation:{' '}
            {strategicRecommendationForVendor(
              selectedVendor.vendor,
              operationalResults.strategic_recommendations
            )}
          </div>}
      </div>
      {selectedVendor != null ? (
        <>
          {selectedVendor.recommendations != null ? (
            <div className='view-recommendations-container'>
              {merchandise == true ? (
                <>
                  <div className='merchandise'>
                    <span>Merchandise</span>
                  </div>
                </>
              ) : null}

              {selectedVendor.recommendations.map((recommendation, index) => (
                <span key={index}>
                  <Card tier={recommendation} />
                </span>
              ))}
            </div>
          ) : null}
          <div className={isCriteriaVisible ? 'hide-criteria-selector' : 'show-criteria-selector'}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCriteriaVisible}
                    onChange={() => setIsCriteriaVisible(!isCriteriaVisible)}
                    color='default'
                  />
                }
                label={isCriteriaVisible ? 'Hide Open POs': 'Show open POs'}
              />
            </FormGroup>
          </div>
          {openPOs != null ? (
            <div ref={criteriaContainerRef}
              className='criteria-container'
              style={{ display: isCriteriaVisible ? '' : 'none' }}>
              <OpenPOTable openPOs={openPOs} />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
